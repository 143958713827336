/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var VAM_UDESIGN_CLASSIC = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        fluidGrid.resize(fluidGrid.selector, fluidGrid.itemSelector, 220, 350);
        $(window).on('resize', function() {
          fluidGrid.resize(fluidGrid.selector, fluidGrid.itemSelector, 220, 350);
        });

        $('.nav-pills, .nav-tabs').tabdrop();

        $('.vam-udesign-classic-go-top').on('click', function(event) {
          $('html, body').animate({scrollTop: 0}, 'fast');
        });

        $('.woocommerce-main-image').on('click', function(event) {
          $('.woocommerce-main-thumb').trigger('click');
        });

        $('.product-loop').each(function(idx, el) {
          $(el).find('.product-loop-item').matchHeight();
        });

        $('.product-loop-carousel').owlCarousel({
          margin: 20,
          items: 4,
          nav: true,
          navSpeed: 400,
          navText : false,
          dots: false,
          responsive: {
            0: {
              items: 1,
              nav: false
            },
            480: {
              items: 2,
              nav: false
            },
            768: {
              items: 3,
              nav: false
            },
            992: {
              items: 4
            },
            1200: {
              items: 4
            }
          }
        });

        $('body').on('click', '.quantity .quantity-control', function() {
          var el = $(this);
          var container = el.closest('.quantity');
          var input = container.find('input.qty');
          var qty = el.is('.quantity-minus') ? -1 : 1;

          var min = parseInt(input.attr('min'), 10);
          if (isNaN(min)) {
            min = 1;
          }
          var max = parseInt(input.attr('max'), 10);
          if (isNaN(max)) {
            max = 0;
          }

          var val = parseInt(input.val(), 10) + qty;
          if (isNaN(val) || val < min) {
            val = min;
          }
          if (max && val > max) {
            val = max;
          }

          input.val(val).change();
        });

        $(document).on('click', '.no-click', function(event) {
          event.preventDefault();
          return false;
        });

        $('[data-toggle="dropdown"][title="Cart"]').parent()
        .on('shown.bs.dropdown', function(event) {
          $(event.target).find('a[data-rel^="prettyPhoto"]').prettyPhoto({
            hook: 'data-rel',
            social_tools: false,
            theme: 'pp_woocommerce',
            horizontal_padding: 20,
            opacity: 0.8,
            deeplinking: false
          });
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('body').tooltip({
          'viewport': 'body',
          'trigger': 'hover',
          'selector': '[data-toggle="tooltip"]'
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.vam-udesign-classic-slider').owlCarousel({
          autoplay: true,
          autoplayTimeout: 4000,
          autoplaySpeed: 4000,
          autoplayHoverPause: true,
          nav: false,
          navSpeed: 2000,
          navText : false,
          dots: true,
          dotsEach: 1,
          dotsSpeed: 2000,
          animateIn: 'fadeIn',
          animateOut: 'fadeOut',
          center: true,
          responsive: {
            1200: {
              items: 1
            },
            992: {
              items: 1
            },
            768: {
              items: 1
            },
            0: {
              items: 1
            }
          }
        });

        featuredProductOptions = {
          margin: 20,
          items: 5,
          nav: true,
          navSpeed: 400,
          navText: false,
          dots: false
        };

        if($('.vam-udesign-classic-featured .container').is('.fluid')) {
          featuredProductOptions.items = 24;
          featuredProductOptions.responsive = {
            0: {
              items: 1
            },
            400: {
              items: 2
            },
            800: {
              items: 4
            },
            1200: {
              items: 6
            },
            1600: {
              items: 8
            },
            2000: {
              items: 10
            },
            2400: {
              items: 12
            },
            2800: {
              items: 14
            },
            3200: {
              items: 16
            },
            3600: {
              items: 18
            },
            4000: {
              items: 20
            }
          };
        }

        $('.featured-product-loop-carousel').owlCarousel(featuredProductOptions);
      },
      finalize: function() {
      }
    },
    // Designer page
    'designer': {
      init: function() {
        // JavaScript to be fired on the designer page
        var container = $('#product-designer-container');
        if (container.length) {
          var containerHeight = Math.max(($(window).innerHeight() -
            container.offset().top - 81), 700);
          container.height(containerHeight);
        }
      },
      finalize: function() {
      }
    },
    'single_product': {
      init: function() {
      },
      finalize: function() {
      }
    },
    'single_design': {
      init: function() {
      },
      finalize: function() {
        $('.design-product-categories a.tab').on('click', function() {
          var categoryID = $(this).data('id');
          if (!categoryID) {
            return;
          }

          $('.dropdown_product_cat').val(categoryID).change();
        });
      }
    },
    'about_us': {
      init: function() {
      },
      finalize: function() {
      }
    },
    'tax_design_cat': {
      init: function() {
      },
      finalize: function() {
      }
    },
    'tax_product_cat': {
      init: function() {
      },
      finalize: function() {
      }
    },
    'post_type_archive_design': {
      init: function() {
      },
      finalize: function() {
      }
    },
    'post_type_archive_product': {
      init: function() {
      },
      finalize: function() {
      }
    }
  };

  var fluidGrid = {
    selector: '.fluid-grid-list',
    itemSelector: '.fluid-grid-list-item',

    resize: function(container, selector, itemWidth, stop) {
        var grid = $(container);
        if (!grid.length) {
            return;
        }

        var gridItems = grid.find(selector);
        if (!gridItems.length) {
            return;
        }

        var gridWidth = Math.ceil(grid.width());
        if (gridWidth < stop) {
            gridItems.css({
                'width': '98%',
            });
        } else {
          var items = Math.floor(gridWidth / itemWidth);
          gridItems.css({
              'width': (96.0 / items) + '%',
              'margin': (2 / items) + '%'
          });
        }

        grid.fadeTo(800, 1);
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = VAM_UDESIGN_CLASSIC;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
